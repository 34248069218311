@font-face {
  font-family: "Montserrat";
  src: url("../../../Assets/font/Montserrat/static/Montserrat-Regular.ttf") format("truetype");
  src: url("../../../Assets/font/Montserrat/static/Montserrat-SemiBold.ttf") format("truetype");

}


.mydata {
  margin: 30px 40px;
}

.k-pdf-export .mydata {
  /* font-family: "DejaVu Sans", "Arial", sans-serif; */
  /* font-size: 50px; */

  /* font-weight: bold; */
  font-family: "Montserrat", sans-serif;
  /* background-color: #68f16c; */
  /* border: 5px solid brown; */
  /* font-family: "Lucida Console", "Courier New", monospace; */

}

/* .kendo-pdf-document .mydata {
  background-color: #68f16c;
} */

.mydata_title {
  font-size: 30px;
  font-weight: 700;
  margin-left: 15px;
  color: #4d4d4d;
}

.mydata_title_contant {
  word-spacing: 7px;
  margin-left: 15px;
  color: #bfbfbf;
  font-size: 15px;
}

.mydata_view_body {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  text-align: center;
  border: 1px solid #adaeaf;
  border-radius: 40px;
  opacity: 1;
}

.mydata_view_body_top {
  font-weight: 700;
  color: #4d4d4d;
  font-size: 30px;
  align-self: center;
}

.mydata_view_body_bottom {
  display: flex;
  justify-content: space-between;
  margin: 0px 30px 0px 30px;
}

.mydata_view_body_bottom .mydata_TOP {
  color: #a2a2a2;
  font-size: 12px;
  margin-right: 0px;
  /* border-bottom: 1px solid #a2a2a2; */
  text-decoration: underline;

}

.mydata_view_body_bottom {
  color: #68f16c;
  font-size: 25px;
  font-weight: 600;
}

.mydata_vartical_graph {
  display: flex;
  text-align: center;
}

.mydata_vartical_graph1 {
  box-shadow: 0px 10px 23px #00000029;
  border: 1px solid #e2e8ef;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 597px;
  height: 330px;
  margin: 20px;
  padding: 20px;
  margin-left: 70px;
}

.mydata_good {
  font-size: 20px;
  font-weight: 600;
  margin-left: 35px;
  color: #68f16c;
}

.mydata_morderate {
  font-size: 20px;
  font-weight: 600;
  margin-right: 10px;
  margin-top: 45px;
  color: #f1f576;
}


.mydata_poor {
  font-size: 20px;
  font-weight: 600;
  margin-left: 35px;
  margin-top: 45px;
  color: #ff4747;
}


.mydata_vartical_graph1_top {
  font-size: 25px;
  font-weight: 500;
  color: #a2a2a2;
}

.mydata_vartical_graph1_bottom {
  display: flex;
  padding-top: 10px;
}

.vl {
  border-left: 6px solid #707070;
}

.mydata_vartical_graph1_bottom_right {
  width: 250px;
  height: 250px;
  padding-top: 30px;
}

.mydata_vartical_graph2_bottom {
  display: flex;
}

/* .recharts-layer .recharts-cartesian-axis .recharts-xAxis .xAxis {
  color: red;
} */
.mydata_vartical_graph2 {
  box-shadow: 0px 10px 23px #00000029;
  border: 1px solid #e2e8ef;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 597px;
  height: 330px;
  margin: 20px;
  padding: 20px;
  margin-right: 70px;
}

.mydata_vartical_graph2_top {
  color: #a2a2a2;
  font-size: 20px;
  font-weight: 500;
}

.mydata_vartical_graph2_bottom {
  width: 100%;
  height: 100%;
  padding-left: 20px;
  padding-top: 10px;
}

.mydata_view_chart_middle_body_right {
  color: #bfbfbf;
  font-size: 15px;
}

.mydata_view_chart_middle_body_right u {
  font-weight: 600;
  font-size: 20px;
}

.mydata_table {
  width: 100%;
  margin: 20px 20px 20px 10px;
  width: 100%;

  display: flex;
  flex-direction: column;
  text-align: center;
  border: 1px solid #707070;
  border-radius: 20px;
  opacity: 1;
}

.mydata_table_top {
  display: flex;

  justify-content: space-between;
}

.mydata_table_top_title {
  margin-left: 485px;
  padding: 10px;

}

/* select.arrow {
  padding: 10px;
} */
.mydata_table_top_logo_body {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 15px;
  margin-right: 87px;
}

.mydata_table_top_logo {
  width: 30px;
}

.mydata_table_top_logo_cont {
  color: #1e90ff;
}

.mydata_table_head {
  border-top: 1px solid #101010;
  opacity: 1;
  padding: 20px;
  margin-top: 20px;
  color: #4d4d4d;
  font-size: 30px;
  font-weight: 600;
}

.mydata_table1 {
  display: flex;
  text-align: center;
  justify-content: space-around;
}

.mydata_table1_left {
  width: 590px;
  margin-left: 2px;
}

.mydata_table1_right {
  width: 590px;
  margin-right: 2px;
}

.view_chart_middle_body_left_mydata {
  width: 100%;
  height: 500px;
}

.view_chart_middle_mydata {
  display: flex;
  padding: 10px;
  margin: 10px 10px 10px 0;
  flex-direction: column;
  box-shadow: 0px 10px 23px #00000029;
  border: 1px solid #e2e8ef;
  border-radius: 10px;
  opacity: 1;
}

.view_chart_middle_top_mydata {
  font-size: 25px;
  color: #a2a2a2;
  display: flex;
  justify-content: space-around;
}

.view_chart_icon {
  margin-left: 10px;
  font-size: 20px;
  margin-top: 5px;
  cursor: pointer;
}

.view_chart_bottom_content {
  color: #a2a2a2;
  font-size: 15px;
  margin: 5px 0px 8px 0px;
}

.view_char_desc {
  color: #a2a2a2;
  font-size: 10px;
  border-radius: 1px solid #a2a2a2;
  width: 200px;
  padding: 5px;
}

.view_char_desc_popover::before {

  content: '';
  position: absolute;
  top: -20px;
  right: 5px;
  border-bottom: 10px solid black;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-top: 10px solid transparent;
  z-index: 10;

}


.mydata_table2 {
  /* margin-left: 110px; */
  margin-right: 50px;
  /* width: 100%; */
  height: 500px;
  padding-bottom: 20px;
  margin: 30px 30px 20px 25px;
  box-shadow: 0px 10px 23px #00000029;
  border: 1px solid #e2e8ef;
  border-radius: 10px;
}

.mydata_table2_header {
  color: #a2a2a2;
  font-size: 25px;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 500;
}

.mydata_table2_body {
  /* width: 80%; */
  max-width: 80%;
  /* min-width: 770px; */
  height: 400px;
}

.bio {
  box-shadow: 0px 10px 23px #00000029;
  border: 1px solid #e2e8ef;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin: 20px 30px 20px 25px;
  padding: 15px;
}

.bio_title {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 25px;
}

.bio_body {
  display: flex;
  margin-top: 5px;
  justify-content: space-between;
}

.bio_body_content {
  color: #4d4d4d;
  font-weight: 600;
}

.mydata_table_footer {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  margin-bottom: 10px;
  padding-left: 120px;
  padding-right: 13px;
}

.mydata_table_footer_left {
  color: #bfbfbf;
  font-size: 12px;
  /* font-family: italic; */
  font-style: italic;
}

.mydata_table_footer_right_img {
  width: 125px;
}

@media screen and (min-width: 1200px) {
  .mydata_good {
    font-size: 17px;
    font-weight: 600;
    margin-left: 30px;
    color: #68f16c;
  }

  .mydata_morderate {
    font-size: 17px;
    font-weight: 600;
    margin-right: 10px;
    margin-top: 50px;
    color: #f1f576;
  }

  .mydata_poor {
    font-size: 17px;
    font-weight: 600;
    margin-left: 35px;
    margin-top: 50px;
    color: #ff4747;
  }
}


@media screen and (min-width: 1900px) {
  .mydata_vartical_graph1 {
    width: 50%;
    height: 430px;
  }

  .mydata_vartical_graph2 {
    width: 50%;
    height: 430px;
  }

  .mydata_view_body_top {
    font-size: 35px;
  }

  .mydata_view_body_bottom .mydata_TOP {
    font-size: 18px;
  }

  .mydata_view_body_bottom {
    font-size: 30px;
  }



  .mydata_vartical_graph1_bottom_right {
    width: 450px;
    height: 350px;
    padding-top: 30px;
  }

  .mydata_good {
    font-size: 25px;
    font-weight: 700;
    margin-left: 35px;
    color: #68f16c;
  }

  .mydata_morderate {
    font-size: 25px;
    font-weight: 700;
    margin-right: 10px;
    margin-top: 83px;
    color: #f1f576;
  }

  .mydata_poor {
    font-size: 25px;
    font-weight: 700;
    margin-left: 35px;
    margin-top: 83px;
    color: #ff4747;
  }

  .mydata_table_top_title {
    margin-left: 699px;
    font-size: 25px;
  }

  .mydata_table1_left {
    width: 700px;
    margin-right: 20px;
    margin-left: 120px;
  }

  .mydata_table1_right {
    width: 700px;
    margin-left: 20px;
    margin-right: 90px;
  }

  .mydata_table2 {
    height: 510px;
  }

  .mydata_table2_body {
    max-width: 85%;
  }
}

@media screen and (min-width: 2500px) {
  .mydata_vartical_graph1 {
    margin-top: 50px;
    width: 50%;
    height: 530px;
  }

  .mydata_vartical_graph2 {
    margin-top: 50px;
    width: 50%;
    height: 530px;
  }

  .mydata_view_body_bottom {
    font-size: 37px;
  }

  .mydata_view_body_top {
    font-size: 37px;
  }

  .mydata_view_body_bottom .mydata_TOP {
    font-size: 22px;
  }

  .mydata_vartical_graph1_bottom_right {
    width: 650px;
    height: 400px;
    padding-top: 30px;
  }

  .mydata_good {
    font-size: 35px;
    font-weight: 700;
    margin-left: 35px;
    color: #68f16c;
  }

  .mydata_morderate {
    font-size: 35px;
    font-weight: 700;
    margin-right: 10px;
    margin-top: 83px;
    color: #f1f576;
  }

  .mydata_poor {
    font-size: 35px;
    font-weight: 700;
    margin-left: 35px;
    margin-top: 83px;
    color: #ff4747;
  }

  .mydata_table_top_title {
    margin-left: 970px;
  }

  .mydata_table1_left {
    width: 900px;
    margin-right: 20px;
    margin-left: 120px;
  }

  .mydata_table1_right {
    width: 900px;
    margin-left: 20px;
    margin-right: 90px;
  }

  .mydata_table2 {
    width: 2200px;
    height: 600px;
  }

  .mydata_table2_body {
    max-width: 90%;
    height: 500px;
  }
}