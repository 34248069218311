.login_logo {
  margin: 20px;
}
.login-contaoner {
  display: flex;
  justify-content: center;
}
.login_wrapper_form {
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #cfd1c6;
  border-radius: 25px;
  padding: 30px 50px;
}
.logo_img {
  border-bottom: unset;
  width: 60%;
  margin: 0 auto;
  margin-bottom: 50px;
}
.login_form {
  display: flex;
  flex-direction: column;
  font-family: Open Sans, sans-serif;
  color: #35424a;
}
.login_form_title {
  color: #515443;
  font-size: 25px;
  font-weight: 600;
}
.login_form_title2 {
  color: #515443;
  font-size: 2rem;
  font-weight: 600;
  /* font-family: Open Sans, sans-serif; */
  font-family: Helvetica, sans-serif !important;


}
.login_form .login_from_input {
  padding: 10px;
  /* padding-left: 10px; */
  border-color: #232323 !important;
  font-family: Helvetica, sans-serif;
  
}

a.forgetlink {
  display: block;
  /* text-align: right; */
  color: #0089F8 !important;
  font-size: 14px;
  margin-top: 10px;
  cursor: pointer;
}
.login_form .form-check {
  color: #5a5e61;
}
.btn_submit {
  color: #fff !important;
  background-color: #38A1F6 !important;
  border-color: #38A1F6 !important;
  font-family: Helvetica, sans-serif !important;
  font-size: 16px;
  font-weight: 700 !important;
  /* width: 100% !important; */
  padding: 6px 10px;
  border-radius: 5px !important;
  margin-top: 10px !important;
  margin-bottom: 20px !important;
  /* align-self: flex-start; */
}
.login_text {
  align-self: center;
  font-size: 16px;
}
.form_futter {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.form_futter_top a {
  color: #1e90ff;
  font-size: 13px;
  margin: 15px;
  text-decoration: underline;
}
.form_futter_bottom {
  color: #4d4d4d;
  font-size: 13px;
  margin: 15px;
}

@media screen and (min-width: 1900px) {
  .login_wrapper_form {
    max-width: 655px;
    /* width: 100%; */
    height: 800px;
    margin: 0 auto;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #cfd1c6;
    border-radius: 25px;
    padding: 30px 50px;
  }
  .login_form_title {
    margin-top: 25px;
    font-size: 40px;
  }
  .login_form {
    margin-top: 20px;
  }
  .form-group {
    margin-top: 20px;
  }
  .form-check-input {
    width: 30px;
    height: 50px;
  }
  .login_form .form-control {
    font-size: 30px;
  }
  .login_form .form-group label {
    font-size: 30px;
  }
  a.forgetlink {
    margin-top: 20px;
    font-size: 30px;
  }
  .btn_submit {
    font-size: 24px;
    padding: 8px 28px;
  }
  .login_text {
    align-self: center;
    margin-top: 20px;
    font-size: 25px;
  }
  .form_futter {
    margin-top: 70px;
  }
  .form_futter_top a {
    font-size: 18px;
  }
  .form_futter_bottom {
    font-size: 18px;
  }
}

@media screen and (min-width: 2500px) {
  .login_wrapper_form {
    max-width: 755px;
    /* width: 100%; */
    height: 900px;
    margin: 0 auto;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #cfd1c6;
    border-radius: 25px;
    padding: 30px 50px;
  }
  .login_form_title {
    margin-top: 25px;
    font-size: 40px;
  }
  .login_form {
    margin-top: 20px;
  }
  .form-group {
    margin-top: 20px;
  }
  .form-check-input {
    width: 30px;
    height: 50px;
  }
  .login_form .form-control {
    font-size: 30px;
  }
  .login_form .form-group label {
    font-size: 30px;
  }
  a.forgetlink {
    margin-top: 20px;
    font-size: 30px;
  }
  .btn_submit {
    font-size: 24px;
    padding: 8px 28px;
  }
  .login_text {
    align-self: center;
    margin-top: 20px;
    font-size: 25px;
  }
  .form_futter {
    margin-top: 70px;
  }
  .form_futter_top a {
    font-size: 18px;
  }
  .form_futter_bottom {
    font-size: 18px;
  }
}
