.dashboard_view_main {
  padding: 30px;
  margin-left: 20px;
}

.view_title {
  display: flex;
  justify-content: space-between;
}

.view_title_left {
  margin-left: 20px;
  margin-top: 20px;
  color: #4d4d4d;
  font-family: "Montserrat";
  font-size: 30px;
  font-weight: 700;
}

.view_title_right {
  display: flex;
  margin-right: 20px;
}

.view_title_right_support {
  font-size: 14px;
  margin-right: 25px;
  margin-top: 10px;
  color: #1e90ff;
}

.view_title_right_logout button {
  font-size: 13px;
  border: 1px solid #1e90ff;
  padding: 2px 20px;
  border-radius: 15px;
  background: white;
  color: #1e90ff;
  margin-top: 5px;
}

.view_body {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  text-align: center;
  /* box-shadow: 0px 10px 23px #00000029; */
  border: 1px solid #adaeaf;
  border-radius: 40px;
  opacity: 1;
}

.view_body_top {
  font-weight: 700;
  font-size: 25px;
  margin-top: 5px;
  color: #4d4d4d;
  margin-right: 70px;
}

.view_body_bottom {
  display: flex;
  justify-content: space-between;
  margin: 0px 30px;
}

.view_body_bottom u {
  color: #a2a2a2;
  font-size: 15px;
}

.view_body_bottom .today {
  font-size: 10px;
}

.view_body_bottom {
  color: #68f16c;
  font-size: 25px;
  font-weight: 600;
}

.view_charts {
  display: flex;
}

.view_chart_one {
  padding: 10px;
  display: flex;
  flex-direction: column;
  margin: 20px 40px;
  border-radius: 15px;
  border: 1px solid #e5e5e6;
}

.view_chart_top {
  font-size: 25px;
  align-self: center;
  font-weight: 700;
  color: #4d4d4d;
}

.view_chart_middle {
  display: flex;
  padding: 10px;
  margin: 10px;
  flex-direction: column;
  box-shadow: 0px 10px 23px #00000029;
  border: 1px solid #adaeaf;
  border-radius: 10px;
}

.view_chart_middle_top {
  align-self: center;
  font-size: 25px;
  color: #a2a2a2;
}

.view_chart_middle_body {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.view_chart_middle_body_left {
  width: 354px;
  /* width: 100%; */
  height: 257px;
  /* padding: 20px; */
  padding-top: 35px;
}

.peak-air-bar {
  width: 100%;
  padding: 20px;
  height: 250px;
}

.view_chart_middle_body_right {
  color: #bfbfbf;
  font-size: 15px;
}

.view_chart_middle_body_right u {
  font-weight: 600;
  font-size: 20px;
}

.view_chart_two {
  margin: 50px 10px;
}

.view_chart_quick {
  display: flex;
  flex-direction: column;
  font-size: 15px;
  /* text-align: center; */
  border-radius: 20px;
  width: 500px;
  margin: 40px 20px;
  padding: 30px 40px 40px 50px;
  box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.43);
  -webkit-box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.43);
  -moz-box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.43);
}

.view_chart_quick_top {
  align-self: center;
  font-size: 20px;
  font-weight: 700;
  color: #1e90ff;
}

.view_chart_quick_body {
  margin-top: 15px;
  word-spacing: 7px;
  font-size: 15px;
  align-self: flex-start;
  color: #a2a2a2;
}

.view_chart_asthma {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  width: 500px;
  margin: 50px 20px;
  padding: 30px 40px 40px 50px;
  box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.43);
  -webkit-box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.43);
  -moz-box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.43);
}

.view_chart_asthma_top {
  font-weight: 700;
  font-size: 20px;
  color: #4d4d4d;
}

.view_chart_asthma_body {
  font-size: 15px;
  margin: 10px 0px;
  color: #a2a2a2;
}

.view_chart_asthma_bottom {
  align-self: flex-end;
  font-size: 14px;
  padding: 10px 35px;
  border-radius: 8px;
  background: #1e90ff;
  color: #ffffff;
  font-weight: 700;
}

@media screen and (min-width: 1900px) {
  .view_body {
    margin-left: 25px;
  }

  .view_body_top {
    font-size: 35px;
  }

  .view_body_bottom u {
    font-size: 20px;
  }

  .view_body_bottom {
    font-size: 30px;
  }

  .view_body_bottom .today {
    font-size: 15px;
  }

  .view_chart_one {
    width: 50%;
  }

  .view_chart_middle_body_right {
    font-size: 25px;
    margin-top: 30px;
    margin-right: 20px;
  }

  .view_chart_middle_body_right u {
    font-weight: 600;
    font-size: 27px;
  }

  .vi .view_chart_two {
    width: 50%;
  }

  .view_chart_middle_body_left {
    width: 550px;
    /* width: 100%; */
    height: 357px;
    /* padding: 20px; */
    padding-top: 35px;
  }

  .view_chart_quick {
    width: 700px;
    height: 375px;
    margin-top: 50px;
    border-radius: 35px;
    font-size: 22px;
  }

  .view_chart_asthma {
    width: 700px;
    height: 275px;
    margin-top: 80px;
    border-radius: 35px;
    font-size: 22px;
  }

  .view_chart_asthma_top {
    font-size: 25px;
  }

  .view_chart_asthma_bottom {
    font-size: 22px;
    padding: 10px 35px;
    border-radius: 8px;
  }
}

@media screen and (min-width: 2500px) {
  .view_body {
    margin: 25px;
  }

  .view_body_bottom {
    font-size: 42px;
  }

  .view_body_top {
    font-size: 42px;
  }

  .view_body_bottom u {
    font-size: 30px;
  }

  .view_body_bottom .today {
    font-size: 18px;
  }

  .view_chart_one {
    width: 50%;
  }

  .view_chart_middle_body_right {
    font-size: 25px;
    margin-top: 30px;
    margin-right: 20px;
  }

  .view_chart_middle_body_right u {
    font-weight: 600;
    font-size: 27px;
  }

  .view_chart_two {
    width: 50%;
  }

  .view_chart_middle_body_left {
    width: 690px;
    /* width: 100%; */
    height: 457px;
    /* padding: 20px; */
    padding-top: 35px;
  }

  .view_chart_quick {
    width: 900px;
    height: 475px;
    margin-top: 50px;
    border-radius: 35px;
    font-size: 30px;
  }

  .view_chart_quick_top {
    font-size: 33px;
  }

  .view_chart_asthma {
    width: 900px;
    height: 375px;
    margin-top: 80px;
    border-radius: 35px;
    font-size: 30px;
  }

  .view_chart_asthma_top {
    font-size: 33px;
  }

  .view_chart_asthma_bottom {
    font-size: 33px;
    padding: 10px 35px;
    border-radius: 8px;
  }
}