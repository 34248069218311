.home-tabs {
  font-family: Helvetica, sans-serif;
}

body {
  font-family: Helvetica, sans-serif;
}

/* .home-tabs .nav-pills .nav-link.active,
.nav-pills .show > .nav-link .hello {
  color: #007bff;
}
.home-tabs .nav-pills .nav-link.active,
.nav-pills .show > .nav-  link img {
  color: #007bff;
} */

.navLinkTitle {
  margin-left: 10px;
  font-size: 16px;
}

.navItemCss {
  padding-left: 20px;
  /* height: 100%; */
}

.nav_bottom {
  font-size: 12px;
  position: absolute;
  bottom: 12%;
  left: 12px;
  color: #adaeaf;
  display: flex;
}
.nav_bottom_contactUs {
  font-size: 12px;
  position: absolute;
  bottom: 20%;
  left: 12px;
  color: #adaeaf;
}
.nav_bottom_contactUs a, .nav_bottom_contactUs b{
  font-size: 12px;
  word-break: break-word;
}
.navcss {
  text-decoration: none;
  color: #adaeaf;
}

.activecss {
  color: #007bff;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background: none;
}

.nav_logo {
  width: 60%;
  margin: 10px 35px;
}

.left-inner-sec {
  padding-bottom: 40px;
  height: 100%;
}

.last-btn {
  text-align: center;
  padding-bottom: 30px;
  position: absolute;
  bottom: 30px;
  right: 0;
  left: 0;
}

.home-tabs .nav-pills .nav-link {
  border-radius: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px 5px;
  font-weight: 500;
  font-size: 14px;
  color: #000;
  text-align: center;
}

.right-bar {
  padding-left: 250px;
  width: 100%;
  padding-bottom: 115px;
  overflow: auto;
  margin-top: 60px;
}

.sidebar {
  position: fixed;
  padding: 28px 22px 10px 15px;
  width: 250px;
  z-index: 2;
  height: 100vh;
  border-right: 3px solid #e5e5e6;
  margin-top: 60px;
}

.page-header img {
  width: 120px;
}

.logo-sec {
  width: 150px;
}

header {
  text-align: center;
  height: 45px;
  line-height: 45px;
  width: 100%;
  background-color: #fff;
  z-index: 99;
}

.home-tabs .nav-pills .nav-link img {
  margin-bottom: 10px;
  width: 30px;
}

.home-tabs .tab-content .tab-pane .main-tab-text {
  padding: 0 20px;
  margin-left: 0px;
  position: relative;
  position: relative;
  padding-right: 50px;
  padding-left: 50px;
}

.radom-div {
  padding: 0px 50px;
  min-height: 45px;
}

.home-tabs .right-bar .tab-content .tab-pane .main-tab-text .main-sub-text {
  border: 1px solid #e0e0e0;
  padding: 30px 40px;
  border-radius: 40px;
}

.welcome-txt h2 {
  margin-right: 70px;
  margin-bottom: 0;
  padding-bottom: 22px;
  font-weight: 700;
  font-size: 60px;
  color: #5a5e61;
  padding-left: 70px;
}

.profile-header {
  display: flex;
  align-items: center;
}

.profile-header img {
  border-radius: 25px;
}

.profile-header h2 {
  margin-right: 70px;
  margin-bottom: 0;
  padding-bottom: 3px;
  margin-left: 30px;
  font-weight: 700;
  border-bottom: 2px solid #b5b5b5;
  color: #5a5e61;
  font-size: 26px;
}

.btn-asthama {
  background-color: #037aff;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  border: 1px solid #037aff;
  border-radius: 8px;
  padding: 14px 27px;
}

.profile-content {
  margin-top: 30px;
}

.profile-content-text {
  margin-bottom: 20px;
  font-size: 18px;
  color: #232323;
}

a.reset-pass {
  color: #006ce5 !important;
  text-decoration: underline;
  font-size: 18px;
  margin-bottom: 10px;
  display: block;
}

a.forgot-name {
  color: #006ce5 !important;
  text-decoration: underline;
  font-size: 18px;
  margin-bottom: 10px;
  display: block;
}

.profile-right-text {
  border: 1px solid #d6d6d6;
  padding: 40px 20px;
  text-align: center;
  font-size: 24px;
}

.profile-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  margin-right: 15px;
  margin-left: 120px;
  background: #fff;
}

.profile-footer span {
  padding: 18px;
}

footer.text-center.footer-sec {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: 15px;
  background: #fff;
}

footer span {
  color: #444444;
}

span.qa {
  font-style: italic;
}

footer span.copy-right {
  font-size: 10px;
  color: #d1d6db;
}

button.btn.btn-logout {
  margin-top: auto;
  background-color: #037aff;
  color: #fff;
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  font-weight: 700;
  border: 1px solid #037aff;
  border-radius: 18px;
  max-width: 150px;
  height: 30px;
  line-height: 17px;
  margin: 20px auto 30px auto;
  display: inline-block;
  padding: 6px 41px;
}

button:focus {
  outline: none;
  box-shadow: none !important;
}

.asthama-header {
  display: flex;
  width: 100%;
  padding: 15px 0;
  padding-bottom: 10px;
}

.asthama-header span img {
  width: 30px;
  top: -5px;
  margin-left: 5px;
  vertical-align: top;
  cursor: pointer;
  position: absolute;
}

.select-wrapper {
  display: block;
  justify-content: center;
  width: 100%;
  text-align: center;
}

.asthama-header .select-wrapper form {
  display: inline-block;
}

.select-wrapper .custom-select {
  width: auto;
  color: #005eff;
  background: #fff url(../../Assets/images/dashboard/caret-down-solid.svg) no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #005eff;
  height: 23px;
  font-size: 14px;
  line-height: normal;
  padding: 0 28px 0 10px;
}

.asthama-graph h3,
.asthma-chart h3 {
  font-size: 16px;
  color: #606060;
  font-weight: 400;
}

.asthama-graph img,
.asthma-chart img {
  max-width: 100%;
}

.asthama-graph {
  margin-top: 15px !important;
}

.asthama-content {
  display: block;
  justify-content: center;
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  padding: 15px 20px 15px 20px;
}

.asthama-text {
  text-align: center;
}

.asthama-text h1 {
  font-size: 38px;
  margin-bottom: 0;
  font-weight: 700;
  color: #e61610;
}

.asthama-text small {
  color: #e61610;
  font-size: 14px;
}

.asthama-text .text-green {
  font-weight: 700 !important;
  color: #00ff38 !important;
  margin-bottom: 0px !important;
}

.text-yellow {
  font-weight: 700 !important;
  color: #fef8a0 !important;
  margin-bottom: 25px;
}

.text-blue {
  font-weight: 700 !important;
  color: blue !important;
  margin-bottom: 25px !important;
}

.text-red {
  font-weight: 700 !important;
  color: #e61610 !important;
  margin-bottom: 25px;
}

.text-black {
  font-weight: 700 !important;
  color: #a2a2a2 !important;
  margin-bottom: 25px !important;
}

.text-green {
  font-weight: 700 !important;
  color: #68f16c !important;
  margin-bottom: 25px;
}

.asthama-text p {
  padding: 0 20px;
  margin: 0;
  font-size: 14px;
}

.asthma-chart {
  border-bottom: 1px solid #d1d6db;
  padding-bottom: 50px;
}

/* modal css */
#MyAsthamaDetails .modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 27px;
  color: #606060;
  text-align: center;
  font-family: Lato, sans-serif;
  font-weight: normal;
  width: 100%;
  letter-spacing: 1px;
}

#MyAsthamaDetails .modal-content {
  border: 1px solid #000;
  border-radius: 25px;
  padding: 25px 20px;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 75%);
}

.cm-btns button {
  width: 100%;
  max-width: 130px;
  font-size: 14px;
  border-radius: 15px;
  margin-right: 15px;
}

#MyAsthamaDetails .modal-header {
  border-bottom: 1px solid #dee2e6;
  margin: 0 60px;
  padding-top: 0;
  padding-bottom: 0px;
}

#MyAsthamaDetails .modal-body h5 {
  font-size: 15px;
  font-weight: bold;
  color: #3b4754;
  margin: 25px 0;
}

#MyAsthamaDetails .modal-body h5 span {
  font-weight: normal;
}

#MyAsthamaDetails .modal-body {
  padding-top: 0;
  font-family: Helvetica, sans-serif;
  border: 4px solid #7373732b;
  border-radius: 20px;
  margin-top: 15px;
}

.allergies_checkboxes {
  display: flex;
  flex-wrap: wrap;
  width: 75%;
}

#MyAsthamaDetails .custom-checkbox {
  margin-bottom: 10px;
}

.allergies_checkboxes .custom-checkbox {
  padding-right: 15px;
  min-width: 80px;
}

#MyAsthamaDetails .custom-control-label::before {
  background-color: #fff;
  border: 2px solid #232323;
}

#MyAsthamaDetails .custom-control-label::after {
  border-radius: 0.25rem;
}

#MyAsthamaDetails .custom-radio .custom-control-input:checked~.custom-control-label::before,
.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
  background-color: #007bff !important;
}

#MyAsthamaDetails .custom-control-label {
  color: #232323;
  font-size: 14px;
}

.medications_checkboxes {
  display: flex;
  flex-wrap: wrap;
}

.medications_checkboxes .custom-checkbox {
  padding-right: 20px;
}

.list_input {
  padding-left: 10px;
  font-size: 12px;
  border: 1px solid #9fafc1;
  border-radius: 3px;
  margin-bottom: 10px;
  max-width: 200px;
  width: 100%;
  height: 28px;
  font-style: italic;
}

input.list_input::placeholder {
  font-style: italic;
  color: #cacaca;
}

.cm-radios,
.cm-ethnicity {
  display: flex;
  align-items: center;
  margin: 15px 0;
  margin-top: 30px;
  justify-content: space-between;
}

.cm-radios h5 {
  font-size: 20px;
  width: 100%;
  margin: 0 0px 0 0 !important;
}

.d-flex.radio-btn-sec .custom-control.custom-radio:first-child {
  margin-right: 15px;
}

.d-flex.radio-btn-sec .custom-control.custom-radio {
  width: 60px;
}

.cm-ethnicity .custom-select {
  width: 350px;
  color: #3b4754;
  background-size: 8px 10px;
  border: 1px solid #3b4754;
  height: 23px;
  font-size: 14px;
  line-height: normal;
  padding: 0 28px 0 10px;
}

.cm-ethnicity h5 {
  color: #4d4d4d;
  font-size: 20px;
  font-weight: 700;
}

.cm-btns {
  text-align: center;
  margin: 50px 0px 20px 0px;
}

.cm-modal-footer p {
  font-size: 12px;
  color: #9c9c9c;
  font-weight: 300;
  font-style: italic;
}

#MyAsthamaDetails button.close {
  position: absolute;
  right: 0px;
  top: 0;
  margin: 10px;
}

#MyAsthamaDetails footer {
  background: transparent;
}

.pc-header {
  padding: 13px 25px;
  border-bottom: 1px solid #e0e0e0;
  position: fixed;
  background: white;
  width: 100%;
  z-index: 9;
  height: auto;
}

.profile-content-text span {
  margin-left: 22px;
}

.edit_pic {
  font-size: 61px;
  margin-left: 0;
  margin-bottom: 0;
  cursor: pointer;
  color: #A2A2A2;
  line-height: 28px;
}

.last-btn p {
  text-align: left;
  margin-bottom: 5px;
  color: #777777;
  font-size: 15px;
  padding-left: 14px;
}

.graph-back {
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  position: relative;
  margin-bottom: 16px;
  padding: 14px 20px;
}

.mst2 {
  background: #ecf3fe;
  border-radius: 20px !important;
}

.info-text-asthema {
  padding: 0;
}

.info-text-asthema p {
  font-size: 22px;
  margin-bottom: 0;
  color: #000000;
  line-height: 30px;
}

.info-text-asthema article {
  font-size: 14px;
  margin-top: 10px;
}

select#time-range {
  /* width: 230px; */
  padding: 7px 13px;
}

select:focus {
  outline: none;
}

p.head-txt {
  color: #605e61;
  font-size: 25px;
}

img.cop-r {
  width: 20px;
  margin-right: 10px;
}

.asthama-title h3 {
  font-size: 24px;
  font-weight: 600;
  color: #000;
  margin-bottom: 20px;
}

.asthama-flex {
  display: flex;
  justify-content: center;
}

/*<!---------------Media------------------------------->*/

@media (max-width: 1200px) {
  .right-bar {
    padding-bottom: 100px;
  }
}

@media (min-width: 1024px) {
  #MyAsthamaDetails .modal-lg {
    max-width: 856px;
  }

  .container {
    max-width: 100%;
    padding: 0 15px;
  }
}

@media (min-width: 1400px) {}

@media (min-width: 1600px) {}