.my-asthama {
  margin: 30px 40px;
}

.my-asthama-title {
  color: #4d4d4d;
  font-weight: 700;
  font-size: 30px;
  margin-left: 25px;
}

.my-asthama-desc {
  color: #bfbfbf;
  word-spacing: 7px;
  font-size: 15px;
  margin-top: 10px;
  margin-left: 25px;
}

.amodal-body {
  padding-top: 0;
  font-family: Helvetica, sans-serif;

  margin-top: 35px;
  padding: 50px;
  box-shadow: 0px 10px 23px #00000029;
  border: 1px solid #a2a2a2;
  border-radius: 10px;
  opacity: 1;
}

.modal-body {
  padding: 0;
}

.amodal-body-h {
  font-family: "Montserrat", sans-serif;
  margin-top: 15px;
  font-size: 20px;
  font-weight: 700;
  color: #4d4d4d;
}

.amodal-body-span {
  font-size: 15px;
  font-weight: 500;
  color: #a2a2a2;
}

.allergies_checkboxes {
  margin: 15px;
  width: 100%;
}

.custom-checkbox {
  margin-left: 25px;
  margin-bottom: 10px;
}

.custom-control-label {
  color: #4d4d4d;
  padding-left: 9px;
  padding-top: 9px;
  font-size: 15px;
}

.custom-control-label::after {
  border-radius: 0.25rem;
  width: 30px;
  height: 30px;
}

.custom-control-label::before {
  background-color: #fff;
  width: 30px;
  height: 30px;
  border: 1px solid #707070;
  opacity: 1;
}

.list_input {
  margin: 10px;
}

.cm-radios .mr-5 {
  color: #4d4d4d;
  font-weight: 700;
}

.radio-btn-sec {
  margin-right: 100px;
}

.cm-ethnicity .form-select {
  border: 1px solid #707070;
  margin-right: 90px;
  height: 40px;
  width: 400px;
  border-radius: 0%;
}

button.close {
  position: absolute;
  right: 0px;
  top: 0;
  margin: 10px;
}

@media screen and (min-width: 1900px) {
  .radio-btn-sec {
    margin-right: 250px;
  }

  .cm-ethnicity .form-select {
    margin-right: 250px;
  }
}