.login-contaoner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.login_wrapper {
  max-width: 455px;
  width: 100%;
  margin: 0 auto;
  border: 1px solid #232323;
  border-radius: 15px;
  padding: 50px;
}
.logo_img {
  /* border-bottom: 1px solid #dee2e6; */
  text-align: center;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 50px;
}
.login_form {
  font-family: Open Sans, sans-serif;
  color: #35424a;
}
.login_form .input_icon {
  position: relative;
}
.login_form .form-control {
  padding: 10px;

  border-color: #232323 !important;
  font-family: Helvetica, sans-serif;
}
.input_icon i {
  position: abssolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  /* color: #1180f1;
      font-size: 16px; */
}
a.forget_link {
  display: block;
  text-align: right;
  color: #006ce5 !important;
  text-decoration: underline;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}
.login_form .form-check {
  color: #5a5e61;
}
.btn-submit {
  color: #fff !important;
  background-color: #037aff !important;
  border-color: #037aff !important;
  font-family: Helvetica, sans-serif !important;
  font-size: 24px !important;
  font-weight: 700 !important;
  width: 100% !important;
  border-radius: 16px !important;
  margin-top: 30px !important;
  margin-bottom: 50px !important;
}
.login-text {
  font-size: 14px;
}
.login-contaoner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.login_wrapper {
  max-width: 455px;
  width: 100%;
  margin: 0 auto;
  border: 1px solid #232323;
  border-radius: 15px;
  padding: 50px;
}
.logo_img {
  /* border-bottom: 1px solid #dee2e6; */
  text-align: center;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 25px;
}
.login_form {
  font-family: Open Sans, sans-serif;
  color: #35424a;
}
.login_form .input_icon {
  position: relative;
}
.login_form .form-control {
  padding: 10px;

  border-color: #232323 !important;
  font-family: Helvetica, sans-serif;
}
.input_icon i {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  /* color: #1180f1;
      font-size: 16px; */
}
a.forget_link {
  display: block;
  text-align: right;
  color: #006ce5 !important;
  text-decoration: underline;
  font-size: 14px;
  margin-top: 16px;
}
.login_form .form-check {
  color: #5a5e61;
}
.btn-submit {
  color: #fff;
  background-color: #037aff;
  border-color: #037aff;
  font-family: Helvetica, sans-serif;
  font-size: 24px;
  font-weight: 700;
  width: 100%;
  border-radius: 16px;
  margin-top: 30px;
  margin-bottom: 50px;
}
.login-text {
  font-size: 14px;
}

.error-text {
  color: red;
}
@media screen and (min-width: 1900px) {
  .login_wrapper {
    max-width: 655px;
    width: 100%;
    margin: 0 auto;
    border: 1px solid #232323;
    border-radius: 15px;
    padding: 50px;
  }
  .login_wrapper_form {
    max-width: 655px;
    /* width: 100%; */
    height: 800px;
    margin: 0 auto;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #cfd1c6;
    border-radius: 25px;
    padding: 30px 50px;
  }
  .login_form_title {
    margin-top: 25px;
    font-size: 35px;
  }
  .login_form {
    margin-top: 20px;
  }
  .form-group {
    margin-top: 20px;
  }
  .form-check-input {
    width: 30px;
    height: 50px;
  }
  .login_form .form-control {
    font-size: 30px;
  }
  .login_form .form-group label {
    font-size: 30px;
  }
  a.forgetlink {
    margin-top: 20px;
    font-size: 30px;
  }
  .btn_submit {
    font-size: 24px;
    padding: 8px 28px;
  }
  .login_text {
    align-self: center;
    margin-top: 20px;
    font-size: 25px;
  }
}
