body {
  font-family: Helvetica, sans-serif;
}
.login-contaoner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.login_wrapper {
  max-width: 455px;
  width: 100%;
  margin: 0 auto;
  border: 1px solid #232323;
  border-radius: 15px;
  padding: 50px;
}
.logo_img {
  text-align: center;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 30px;
}
.logo_img p {
  margin-top: 10px;
  color: #3b4754;
  font-size: 24px;
  font-weight: 500;
}
.login_form .form-group label {
  font-size: 13px;
  text-align: left;
  display: block;
}
.login_form .form-group .input_icon input::placeholder {
  font-style: italic;
  font-size: 16px;
  color: #a0a0a0;
  font-weight: 400;
}
.login_form {
  color: #35424a;
}
.login_form .input_icon {
  position: relative;
}
.login_form .form-control {
  padding: 10px;
  padding-left: 65px;
  border-color: #232323 !important;
  font-family: Helvetica, sans-serif;
}
/* .form-check label {
  font-size: 15px;
} */
.input_icon i {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  /* color: #1180f1;
    font-size: 16px; */
}
a.forget_link {
  display: block;
  text-align: left;
  color: #006ce5 !important;
  text-decoration: underline;
  font-size: 14px;
  margin-top: 0px;
}
.login_form .form-check {
  color: #5a5e61;
  display: block;
  text-align: left;
}

.btn-submit {
  color: #fff;
  background-color: #037aff;
  border-color: #037aff;
  font-family: Helvetica, sans-serif;
  font-size: 24px;
  font-weight: 700;
  width: 86%;
  border-radius: 16px;
  margin: 50px auto;
  display: block;
  padding: 10px 20px;
}
.btn-submit:hover {
  background-color: #0960c1;
  transition: 1s;
}
p.login-text {
  font-size: 14px;
}
p.login-text a {
  text-decoration: underline;
}

.asthmaModal .modal-content {
  border: 1px solid #000;
  border-radius: 25px;
  padding: 25px 20px;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 75%);
}
.asthmaModal .modal-title.h4 {
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 27px;
  color: #000;
  text-align: center;
  font-family: Lato, sans-serif;
  font-weight: 800;
  width: 100%;
  letter-spacing: 1px;
}
.asthmaModal .modal-header {
  border-bottom: 1px solid #dee2e6;
  margin: 0 60px;
  padding-top: 0;
  padding-bottom: 0px;
}
.asthmaModal .amodal-body {
  padding-top: 0;
  font-family: Helvetica, sans-serif;
  border: 1px solid #a2a2a2;
  border-radius: 20px;
  margin-top: 15px;
  padding: 1rem;
}
.asthmaModal .modal-body {
  padding: 0;
}
.asthmaModal .modal-body h5 {
  font-size: 15px;
  font-weight: bold;
  color: #3b4754;
  margin: 25px 0;
}
.asthmaModal .modal-body h5 span {
  font-weight: normal;
}
.asthmaModal .custom-checkbox {
  margin-bottom: 10px;
}
.asthmaModal .custom-control-label {
  color: #232323;
  font-size: 14px;
}
.asthmaModal .custom-control-label::after {
  border-radius: 0.25rem;
}
.asthmaModal .custom-control-label::before {
  background-color: #fff;
  border: 2px solid #232323;
}
.asthmaModal button.close {
  position: absolute;
  right: 0px;
  top: 0;
  margin: 10px;
}

.profileImg {
  position: relative;
}

.profileImg .file-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  background-color: #ffffff;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.pdfTable.all-sec {
  position: relative;
  height: unset;
}
.custom-layout.down-btn {
  position: absolute;
  right: 20%;
  top: 50%;
  transform: translateY(-50%);
}
.asthama-header button {
  opacity: 0;
  display: inline-block;
  width: 40px;
  height: 30px;
  cursor: pointer;
  padding: 0;
}
.asthama-header span {
  position: relative;
}
.file-label {
  position: absolute;
  margin: 0;
  display: block;
  top: 17px;
  right: 37px;
}
.pdfTable {
  width: 74%;
  height: 100%;
  margin: 0 auto;
}
@media screen and (min-width: 1900px) {
  .login_wrapper_form {
    max-width: 655px;
    /* width: 100%; */
    height: 800px;
    margin: 0 auto;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #cfd1c6;
    border-radius: 25px;
    padding: 30px 50px;
  }
  .login_form_title {
    margin-top: 25px;
    font-size: 35px;
  }
  .login_form {
    margin-top: 20px;
  }
  .form-group {
    margin-top: 20px;
  }
  .form-check-input {
    width: 30px;
    height: 50px;
  }
  .login_form .form-control {
    font-size: 30px;
  }
  .login_form .form-group label {
    font-size: 30px;
  }
  a.forgetlink {
    margin-top: 20px;
    font-size: 30px;
  }
  .btn_submit {
    font-size: 24px;
    padding: 8px 28px;
  }
  .login_text {
    align-self: center;
    margin-top: 20px;
    font-size: 25px;
  }
}
