.my-account {
  margin: 20px 40px;
}
.account_header {
  margin-left: 30px;
}
.account_header_titel {
  color: #4d4d4d;
  font-size: 30px;
  font-weight: 700;
}
.account_header_desc {
  color: #bfbfbf;
  margin-top: 10px;
  font-size: 15px;
}
.account_body {
  display: flex;
  text-align: center;
  margin: 30px 100px;
}
.profile_header {
  margin-bottom: 20px;
}

.profile_img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border-style: solid;
  border-color: #707070;
  border-width: 1px;
}
.profile_name {
  margin: 10px 0px;
  font-size: 25px;
  font-weight: 700;
  color: #4d4d4d;
}
.profile_title {
  margin-top: 12px;
  color: #4d4d4d;
  font-size: 15px;
}
.profile_title_cont {
  color: #bfbfbf;
  font-size: 15px;
  font-weight: 600;
}
a.reset_pass {
  color: #1e90ff !important;
  text-decoration: underline;
  font-size: 16px;
  font-weight: 700;
  margin-top: 35px;
  margin-bottom: 10px;
  display: block;
}
.account_body_right_form {
  margin-left: 100px;
  padding: 40px;
  width: 611px;
  box-shadow: 0px 10px 23px #00000029;
  border: 1px solid #e2e8ef;
  border-radius: 10px;
}
.Myaccount_form_label label {
  font-weight: 700;
  color: #4d4d4d;
  margin-top: 5px;
}
.account_body_right_button {
  width: 150px;
  height: 35px;
  margin-top: 20px;
  background-color: #1e90ff;
  border-radius: 50px;
  color: #e2e8ef;
}
.myAccount_futter_bottom {
  text-align: center;
  margin-top: 40px;
  color: #4d4d4d;
  font-size: 13px;
}

@media screen and (min-width: 1900px) {
  .myAccount_futter_bottom {
    margin-left: 650px;
    text-align: start;
    margin-top: 100px;
    color: #4d4d4d;
    font-size: 13px;
  }
}
